import {
  User,
  Brik,
  Customer,
  MessageDialog,
  PaginatedResponse,
  PaginationRequest,
  Update,
  Create,
  UserPlan,
  UserPlanFeature,
  UserPlanFeatureHandler,
  BrikId,
  UserPlanFeatureContext,
  UserPlanFeatureResponse,
  UserPlanUsage,
  CommonFeature,
} from '@softbrik/data/models';
import { Injectable, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, mergeAll, shareReplay, tap } from 'rxjs/operators';
import {
  asyncScheduler,
  BehaviorSubject,
  Observable,
  of,
  scheduled,
  throwError,
} from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { createParams, isBrikId, isDevDomain } from './utils';
import {
  StorageKeyHandler,
  createKeyHandler,
  StorageType,
} from '@softbrik/shared/helpers';
import { countries, countryCodes, faqs } from './data';
import { DOCUMENT } from '@angular/common';
import {
  AnalyticsClientFacade,
  create as createAnalyticsClient,
} from './analytics';
import featureHandlers from './features';

const ALL_BRIKS = ['stak', 'trust', 'survey', 'file', 'admin', 'contact'];
const ADMIN_BRIKS = ['admin', 'contact'];

const DEFAULT_FREE_PLAN: UserPlan = {
  name: 'free',
  features: [
    {
      id: 1,
      brik_id: BrikId.Admin,
      name: 'users create',
      max: 999999999,
      type: 'max',
      balance: null,
      list: null,
    },
    {
      id: 2,
      brik_id: BrikId.Stak,
      name: 'issues create',
      max: 100,
      type: 'max',
      balance: null,
      list: null,
    },
    {
      id: 3,
      brik_id: BrikId.Trust,
      name: 'forms create',
      max: 3,
      type: 'max',
      balance: null,
      list: null,
    },
    {
      id: 4,
      brik_id: BrikId.Trust,
      name: 'use language',
      type: 'list',
      list: ['ALL'],
      max: null,
      balance: null,
    },
    {
      id: 4,
      brik_id: BrikId.Trust,
      name: 'use design',
      type: 'list',
      list: ['default'],
      max: null,
      balance: null,
    },
    {
      id: 4,
      brik_id: BrikId.Survey,
      name: 'surveys create',
      max: 1,
      type: 'max',
      balance: null,
      list: null,
    },
  ],
};

const cmpBriks = (a: Brik, b: Brik) => {
  let aIndex = ALL_BRIKS.indexOf(a.brik_id);
  let bIndex = ALL_BRIKS.indexOf(b.brik_id);

  aIndex = aIndex === -1 ? 999 : aIndex;
  bIndex = bIndex === -1 ? 999 : bIndex;

  if (aIndex === bIndex) return 0;

  return aIndex > bIndex ? 1 : -1;
};

type AppPage = {
  sidebar: boolean;
  topbar: boolean;
  layout: 'default' | 'plain';
};

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public store: StorageKeyHandler = createKeyHandler('app', StorageType.LOCAL);
  public session: StorageKeyHandler = createKeyHandler(
    'app',
    StorageType.SESSION
  );
  @Output() messageDialogResult = new EventEmitter<boolean>();

  public API_LINK = '';

  branch: string;
  version: string;
  user: Observable<User>;
  plan: Observable<UserPlan>;
  private planSubject: BehaviorSubject<UserPlan>;
  private userSubject: BehaviorSubject<User>;

  public is_loading: boolean = false;

  page: AppPage = { sidebar: true, topbar: true, layout: 'default' };

  /** @deprecated not reliable await new approach */
  public showPreview = false;
  public showHelp: boolean = false;
  public isShowMessage: boolean = false;
  public dialogMessage: MessageDialog;
  public showNotification: boolean = false;
  public notificationTitle: string;
  public notificationText: string;
  public notificationType: string;
  public notificationTimeout = 5000;
  public feedbackDone: boolean = false;

  public analytics: AnalyticsClientFacade;

  private featureHandlers: UserPlanFeatureHandler[];
  private featureFlags_: string[];
  featureFlags$ = new BehaviorSubject<string[]>([]);

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) {
    if (!this.API_LINK) {
      this.API_LINK = localStorage.getItem('APP_API_LINK');
    }
    this.initFeatureFlags();
    this.initUser();
    this.initPlan();
    this.initAnalytics();
  }

  get currentBrik() {
    const name = this.router.url.replace(/^\//, '').split('/').shift();
    return name || 'dashboard';
  }

  set currentBrik(_value: string) {
    throw new Error('Brik is readonly');
  }

  /**
   * Get customer alias. For local and ngrok it falls back to 'softdrinks'.
   */
  get customerAlias() {
    return isDevDomain(this.document.location.hostname)
      ? 'softdrinks'
      : this.document.location.hostname.split('.')[0];
  }

  get business(): 'support' | 'health' | null {
    return this.decodeToken()?.business || this.getQueryParam('business');
  }

  get domain() {
    return window.location.hostname.split('.').slice(-2).join('.');
  }

  /**
   * Get the domain URL under any environment: local, ngrok'ed, or in
   * production.
   */
  get domainUrl() {
    const domain = this.document.location.hostname;
    const port = this.document.location.port;
    const port_ = port ? `:${port}` : '';
    return isDevDomain(domain)
      ? `${this.document.location.protocol}//${domain}${port_}`
      : `https://${this.customerAlias}.${this.domain}`;
  }

  /**
   * Creates a public URL with the proper language, customer and business
   * settings.
   */
  createPublicURL(path: string, params?: Record<string, string>) {
    const params_ = this.createPublicURLParams(params?.language || 'en');
    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        // Language is already added
        if (key === 'language') return;

        params_.append(key, value);
      });
    }
    return `${this.domainUrl}/${path}?${params_}`;
  }

  /**
   * Create the query params most likely need for any public URL.
   *
   * @see createPublicURL()
   */
  createPublicURLParams(language: string) {
    return new URLSearchParams({
      customer: this.customerId.toString(),
      business: this.business || 'support',
      language: language || 'en',
    });
  }

  /**
   * Customer ID from token
   */
  get customerId() {
    return this.decodeToken()?.customer_id;
  }

  /**
   * Get customer ID from token or query string. If you don't know why you
   * should use this method, then you should use `customerId`.
   */
  get customerIdAny() {
    return (
      this.customerId ||
      this.getQueryParam('customer') ||
      this.getQueryParam('customerId')
    );
  }

  private getQueryParam(key: string) {
    return this.route.snapshot.queryParams?.[key];
  }

  private enableFeature(feature: string) {
    this.analytics.emit({
      name: 'app: features enabled',
      payload: { feature },
    });
    this.featureFlags = Array.from(new Set([...this.featureFlags, feature]));
  }

  featureEnabled(feature: string) {
    return this.featureFlags?.includes(feature) || false;
  }

  private initFeatureFlags() {
    this.featureFlags$.next(this.featureFlags);
    this.route.queryParams.subscribe((params) => {
      params.features?.split(',').forEach((feature: string) => {
        this.enableFeature(feature);
      });
    });
  }

  private initUser() {
    this.userSubject = new BehaviorSubject<User>(this.restoreUser());
    this.user = this.userSubject.asObservable();
  }

  private initPlan() {
    this.planSubject = new BehaviorSubject<UserPlan>(this.restorePlan());
    this.plan = this.planSubject.asObservable();
    this.featureHandlers = featureHandlers;
  }

  private initAnalytics() {
    const isPublic = window?.location?.pathname?.startsWith('/p');
    createAnalyticsClient({
      appVersion: this.version,
      appBranch: this.branch,
      customerAlias: this.customerAlias,
      options:
        isPublic && this.business === 'health'
          ? {
              includeUtm: false,
              includeGclid: false,
              includeFbclid: false,
              includeReferrer: false,
              trackingOptions: {
                city: false,
                country: false,
                carrier: false,
                device_manufacturer: false,
                device_model: false,
                dma: false,
                ip_address: false,
                language: false,
                os_name: false,
                os_version: false,
                platform: false,
                region: false,
                version_name: false,
              },
            }
          : {},
    }).then((analytics) => {
      this.analytics = analytics;
    });
  }

  restoreUser(): User | null {
    const userJson = localStorage.getItem('user');
    return userJson ? JSON.parse(userJson) : null;
  }

  restorePlan(): UserPlan | null {
    return this.session.getItem('plan');
  }

  createFeatureContext(
    extend?: Partial<UserPlanFeatureContext>
  ): UserPlanFeatureContext {
    const plansEnabled = this.featureFlags_?.includes('plans');

    return {
      business: this.business,
      customerAlias: this.customerAlias,
      plan: plansEnabled ? this.currentPlan.name : 'free',
      ...extend,
    };
  }

  get featureFlags() {
    if (!this.featureFlags_) {
      this.featureFlags_ = this.session.getItem('featureFlags') || [];
    }
    return this.featureFlags_;
  }

  set featureFlags(value: string[]) {
    this.session.setItem('featureFlags', value);
    this.featureFlags_ = value;
    this.featureFlags$.next(value);
  }

  canUse(
    brikId: string,
    featureName: string,
    ctx?: Partial<UserPlanFeatureContext>,
    options?: { emit?: boolean }
  ): UserPlanFeatureResponse {
    if (!isBrikId(brikId)) {
      throw new Error(`Invalid brik id: ${brikId}`);
    }

    const feature = this.getFeature(brikId, featureName);
    if (!feature) {
      console.log('Missing feature', brikId, featureName);
      return {
        allowed: false,
      };
    }

    const handler = this.getFeatureHandler(feature);
    if (!handler) {
      console.log('Missing handler', brikId, featureName);
      return {
        allowed: false,
      };
    }

    const ctx_: UserPlanFeatureContext = {
      ...(ctx || {}),
      customerAlias: this.customerAlias,
      business: this.business,
      plan: this.currentPlan.name,
    };

    const response = handler.can(feature, ctx_);

    if (response.data) {
      response.data.plan = this.currentPlan.name;
    }

    if (options?.emit !== false && !response.allowed) {
      this.analytics.emit({ name: 'plan: limit met', payload: response });
    }

    return response;
  }

  getFeature(brikId: BrikId, featureName: string): UserPlanFeature | null {
    return this.currentPlan?.features.find((feature) => {
      return feature.brik_id === brikId && feature.name === featureName;
    });
  }

  getFeatureHandler(feature: UserPlanFeature): UserPlanFeatureHandler | null {
    return this.featureHandlers.find((handler) => {
      return (
        handler?.brik_id === feature.brik_id &&
        handler?.name === feature.name &&
        handler?.type === feature.type
      );
    });
  }

  navigate(route: any) {
    // TODO call from menuService
    // this.clearSidebar();
    this.router.navigate([route]);
  }

  childNavigate(
    route: string,
    outlet: string,
    child: string,
    params: any[] = []
  ) {
    this.router.navigate([
      `/${route}`,
      { outlets: { [outlet]: [child, ...params] } },
    ]);
  }

  public get currentUser(): User {
    return this.userSubject.value;
  }

  get currentPlan(): UserPlan {
    return this.planSubject.value || DEFAULT_FREE_PLAN;
  }

  public decodeToken() {
    const user = this.restoreUser();
    if (!user) return;
    return this.decode(user.token);
  }

  public decode<T = any>(token: string | null) {
    const jwt = new JwtHelperService();
    return jwt.decodeToken<T>(token);
  }

  fetchFeatures() {
    const commonApi = localStorage.getItem('CONTACT_API_LINK');
    const query = createParams({
      count: 999,
    });
    return this.http.get<PaginatedResponse<CommonFeature>>(
      `${commonApi}/features?${query}`
    );
  }

  updateFeatures() {
    return this.fetchFeatures().pipe(
      tap(({ data: features }) => {
        features.forEach((feature) => this.enableFeature(feature.name));
      })
    );
  }

  fetchPlan(): Observable<UserPlan> {
    const commonApi = localStorage.getItem('CONTACT_API_LINK');
    return this.http
      .get<UserPlan>(`${commonApi}/plan`)
      .pipe(
        // @ts-ignore
        map((plan: { name: string; limits: UserPlanFeature[] }) => {
          return {
            name: plan.name,
            features: plan.limits,
          } as unknown as UserPlan;
        })
      )
      .pipe(
        catchError((error) => {
          console.error('Caught plan error, using default plan', error);
          return of(DEFAULT_FREE_PLAN);
        })
      );
  }

  refreshPlan() {
    return this.fetchPlan().pipe(
      map((plan) => {
        this.session.setItem('plan', plan);
        this.planSubject.next(plan);
        return plan;
      })
    );
  }

  getPlanUsage() {
    const commonApi = localStorage.getItem('CONTACT_API_LINK');
    return this.http.get<UserPlanUsage>(`${commonApi}/plan-usage`);
  }

  login(credentials: { email: string; password: string }) {
    return this.http
      .post<User>(`${this.API_LINK}/auth/user/login`, credentials)
      .pipe(
        map((user) => {
          localStorage.setItem('user', JSON.stringify(user));
          this.userSubject.next(user);

          this.updateBusiness();

          this.analytics.setProperties({
            userId: user.id,
            email: user.email,
            name: [user.first_name, user.last_name].join(' '),
            business: this.business,
          });

          this.analytics.emit({ name: 'app: logged in' });

          return user;
        })
      );
  }

  /**
   * Update business and reload if needed
   */
  private updateBusiness() {
    const existingBusiness = window.localStorage.getItem('business');
    const business = this.business || existingBusiness;
    window.localStorage.setItem('business', business);
    if (existingBusiness && existingBusiness !== business) {
      window.localStorage.removeItem('translations');
      window.location.reload();
    }
  }

  logout() {
    this.analytics.emit({ name: 'app: logged out' });
    localStorage.removeItem('user');
    this.userSubject.next(null);
    this.is_loading = false;
    this.router.navigate(['/login']);
  }

  isAuthenticated() {
    return this.router.url != '/login' && this.currentUser;
  }

  changePassword(credentials: {
    email: string;
    password: string;
    new_password: string;
    confirm_password: string;
    customer_alias: string;
  }) {
    this.analytics.emit({ name: 'app: change password' });
    return this.http.post(
      `${this.API_LINK}/auth/user/change-password`,
      credentials
    );
  }

  getUserCommonBriks() {
    return this.getUserBriks().pipe(
      map((briks) => briks.filter((brik) => !ADMIN_BRIKS.includes(brik.id)))
    );
  }

  getUserAdminBriks() {
    return this.getUserBriks().pipe(
      map((briks) => briks.filter((brik) => ADMIN_BRIKS.includes(brik.id)))
    );
  }

  getUserBriks() {
    return scheduled<Observable<Brik[]>>(
      [
        of(this.session.getItem('briks') || []),
        this.http.get<Brik[]>(`${this.API_LINK}/auth/user/briks`),
      ],
      asyncScheduler
    )
      .pipe(
        mergeAll(),
        tap((briks) => this.session.setItem('briks', briks))
      )
      .pipe(map((briks) => briks.sort(cmpBriks)))
      .pipe(shareReplay());
  }

  getUsers({ link, ...params }: PaginationRequest & { link: string }) {
    const query = createParams(params);
    return this.http.get<PaginatedResponse<User>>(
      `${link}/auth/customer/users?${query}`
    );
  }

  getAccount(link: string) {
    return this.http.get<Customer>(`${link}/sub/subscription`);
  }

  getInvoices(link: string, query?: string) {
    return this.http.get<PaginatedResponse<any>>(
      `${link}/sub/customer/invoice?${query}`
    );
  }

  getPaymentMethod(link: string) {
    return this.http.get(`${link}/sub/payment-method`);
  }

  createPaymentMethod(link: string, data: unknown) {
    this.analytics.emit({ name: 'app: add payment method' });
    return this.http.post(`${link}/sub/payment-method`, data);
  }

  deletePaymentMethod(link: string, id: string | number) {
    this.analytics.emit({ name: 'app: delete payment method' });
    return this.http.delete(`${link}/sub/payment-method/${id}`);
  }

  updateCustomer(customer: User) {
    return this.http.put<User>(
      `${this.API_LINK}/auth/customer/${customer.id}`,
      customer
    );
  }

  createUser(user: Create<User>) {
    return this.http.post(`${this.API_LINK}/auth/user/register`, user);
  }

  updateUser(user: Update<User>) {
    return this.http.put<User>(`${this.API_LINK}/auth/user/${user.id}`, user);
  }

  toggleLogin(user: User) {
    return this.http.put(`${this.API_LINK}/auth/toggle-login`, user);
  }

  sendPasswordResetEmail(email: string, customer_alias: string) {
    return this.http.post(`${this.API_LINK}/auth/user/reset`, {
      email,
      customer_alias,
    });
  }

  toggleShowMessage(
    message: Partial<MessageDialog> & { content: string | Promise<string> }
  ) {
    this.dialogMessage = {
      type: 'confirm',
      showOk: true,
      showCancel: false,
      ...message,
    };

    if (typeof message.title === 'string') {
      this.dialogMessage.title = Promise.resolve(message.title);
    }
    if (typeof message.content === 'string') {
      this.dialogMessage.content = Promise.resolve(message.content);
    }
    if (typeof message.okLabel === 'string') {
      this.dialogMessage.okLabel = Promise.resolve(message.okLabel);
    }
    if (typeof message.cancelLabel === 'string') {
      this.dialogMessage.cancelLabel = Promise.resolve(message.cancelLabel);
    }

    this.isShowMessage = !this.isShowMessage;
  }

  async notify(
    title: string | Promise<string>,
    text: string | Promise<string> = '',
    type: 'success' | 'error' = 'success',
    timeout: number = 5000
  ) {
    this.notificationTitle = typeof title === 'string' ? title : await title;
    this.notificationText = typeof text === 'string' ? text : await text;
    this.notificationType = type;
    this.notificationTimeout = timeout;

    this.showNotification = true;

    setTimeout(() => {
      this.showNotification = false;
      this.notificationTitle = '';
      this.notificationText = '';
      this.notificationType = '';
    }, timeout + 1000);
  }

  async notifyError(
    message: string | Promise<string>,
    error: Error,
    timeout?: number
  ) {
    const body = `${await message}: ${
      typeof error === 'string' ? error : error.message
    }`;
    this.analytics.emit({ name: 'app: error', payload: { message: body } });
    this.notify('Error', body, 'error', timeout);
    console.error(error);
  }

  public countries: Array<string> = countries;
  public countryCodes: Array<any> = countryCodes;
  public helpItems = faqs;

  generatePageTemplate(value: string, title?: string) {
    return `
    <html>
        <head>
          <title>${title || 'Print'}</title>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css">
          <style>
            body {
              display: flex;
              flex: 1;
              justify-content: center;
              align-items: center;
            }
            body.label.landscape .sheet {
              width: 66.675mm;
              height: 66.675mm;
              padding: 8px;
              flex-direction: column;
              align-content: center;
              align-items: center;
              justify-content: center;
            }
            @page {
              size: label landscape
            }
            article {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            img {
              height: 80%;
              width: 80%;
            }
            h3 {
              font-family: 'Montserrat', sans-serif;
            }
          </style>
        </head>
        <body class="label landscape">
          <section class="sheet">
            <article>
              <img src="${value}" />
              <br/>
              <h3>Scan to give feedback</h3>
            </article>
          </section>
        </body>
      </html>`;
  }

  togglePreview() {
    this.analytics.emit({ name: 'trust: toggle preview' });
    this.showPreview = !this.showPreview;
  }

  goToUpgrade(payload: Record<string, any> = {}) {
    this.analytics.emit({
      name: 'plan: upgrade',
      payload,
    });

    setTimeout(() => {
      window.location.href = 'https://www.softbrik.com/upgrade';
    }, 50);
  }

  reInviteUser(userId: string) {
    const url = `https://4y1f7m3wed.execute-api.eu-west-1.amazonaws.com/dev/user/register/${userId}`;
    return this.http.post(url, {}).pipe(
      catchError((error) => {
        console.error('Error while re-inviting user with id:', userId, error);
        return throwError(error);
      })
    );
  }

  getStripePortalLink(): Observable<any> {
    const user = localStorage.getItem('user');
    const obj = user ? JSON.parse(user) : null;
    const token = obj ? obj.token : null;

    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<any>(`${this.API_LINK}/sub/portal/session`, {
      headers,
    });
  }

  makeUserAdmin(
    id: number,
    is_admin: boolean,
    token: string
  ): Observable<User> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.put<User>(
      `${this.API_LINK}/auth/user/${id}`,
      { is_admin },
      { headers }
    );
  }
}
