export const environment = {
  production: true,
  branch: 'development',
  bugsnagApiKey: '121ea2a1a1400f165e54cb2ce7365249',
  api: 'https://dev-api.softbrik.com',
  allo: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  stak: 'https://tvg54lz2te.execute-api.eu-west-1.amazonaws.com/prod',
  trak: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  file: 'https://s5k8c4pdcd.execute-api.eu-west-1.amazonaws.com/prod',
  contacts: 'https://wiqyn6rjq0.execute-api.eu-west-1.amazonaws.com/prod',
  trust: 'https://rc6g6rtra7.execute-api.eu-west-1.amazonaws.com/prod',
  health: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  survey: 'https://40cjcrgmul.execute-api.eu-west-1.amazonaws.com/prod',
  ws: 'wss://cpqi7oj4yi.execute-api.eu-west-1.amazonaws.com/devTesting',
  export:
    'https://vi1ur5ir2k.execute-api.eu-west-1.amazonaws.com/devTesting/surveys/',
};
