import { Component, HostBinding, OnInit } from '@angular/core';
import { AppService } from '@softbrik/data/services';
import { getPersistedLanguage, persistLanguage } from '@softbrik/translate';

@Component({
  selector: 'softbrik-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.css'],
})
export class ChangeLanguageComponent implements OnInit {
  @HostBinding('class.page') isPage = true;

  public language: string = 'en';

  public languages = [
    { code: 'en', label: 'English' },
    { code: 'da', label: 'Dansk' },
    { code: 'de', label: 'Deutch' },
    { code: 'fr', label: 'Français' },
    { code: 'es', label: 'Español' },
    { code: 'pt', label: 'Português' },
  ];

  constructor(public app: AppService) {}

  ngOnInit(): void {
    this.language = getPersistedLanguage();
  }

  select(language: string) {
    this.language = language;
  }

  save() {
    persistLanguage(this.language);
    // @ts-ignore
    window.location = '/dashboard';
  }

  cancel() {
    this.app.navigate('account/settings');
  }
}
